<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="questionnaire" service="refCredit" backlink="/refinance-credit/expense" />
      <div class="content">
        <AboutExpenses service="refCredit" nextlink="/refinance-credit/obligations" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutExpenses from '@/components/service/AboutExpenses.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Refinance_credit_about_expences',
  components: { Backlink, AboutExpenses }
}
</script>
